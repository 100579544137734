<template>

  <div>

    <div class="generateZahvalaDonatorjuOuterContainer">

      <div class="" v-if="donorData">

        <div class="generateZahvalaDonatorjuNaslovnicaContainer">
          <img :src="require('@/assets/images/content/business_letters/zahvala_donatorjem.jpg')" class="bgImg">
          <h3>O KORIŠČENJU DONACIJE ZA IZVAJANJE STORITVE<br/>BREZPLAČNIH PREVOZOV ZA STAREJŠE<br/>
            <small>pri donatorju {{ donorData.donorData.companyName }} -
              {{ donorData.donorData.companyDisplayName }}</small></h3>

          <!--            "{{donorData.donorData.companyName}}" ({{donorData.donorData.companyDisplayName}})-->
          <h4>{{ moment(startDate).startOf('month').format('D. M. YYYY') }} -
            {{ moment(endDate).endOf('month').format('D. M. YYYY') }}</h4>

        </div>

      </div>

    </div>

    <div class="generateZahvalaDonatorjuOuterContainer">

      <div class="" v-if="donorData">

        <div class="generateZahvalaDonatorjuContainer">
          <img :src="require('@/assets/images/content/business_letters/businessLetterBg.png')" class="bgImg">

          <p style="text-align: right">{{ donorData.data[0].unit.locationForContracts }},
            {{ moment(endDate).format('MMMM') }} {{ moment(endDate).format('YYYY') }}</p>
          <br/><br/>

          <h3>Spoštovani donator!</h3>

          <div v-if="reportType !== 'otherDonationDonor'">
            <p>Vašo donacijo smo namenili za prostovoljce, ki opravljajo brezplačne prevoze za
              starejše z namenskimi vozili v Zavodu Sopotniki. Brez prostovoljcev storitev ne bi
              bila to, kar je. Med prevozi se prostovoljci posvetijo pogovoru s starejšimi, jih
              nasmejijo in razbremenijo skrbi. Po potrebi starejše tudi spremljajo po opravkih.
              Podpora prostovoljcem je zato izrednega pomena pri doseganju viskoke stopnje
              zaupanja s strani starejših, ki jim je storitev namenjena.</p><br/>
            <p>Hvala še enkrat za vašo izjemno pomoč, ki našim prostovoljcem omogoča lažje in
              lepše delo!</p><br/><br/>
          </div>
          <div v-if="reportType === 'otherDonationDonor'">
            <p>Vašo donacijo smo namenili za vzdrževanje namenskega vozila, s katerim v Zavodu Sopotniki opravljamo brezplačne prevoze za starejše. Skrbno vzdrževanje namenskih vozil v zavodu je izrednega pomena za varnost in udobje voženj starejših. V vozilu se vsak dan izmenja več uporabnikov. Želimo si, da naredimo vse kar je v naši moči, da se starejši s Sopotniki počutijo dobro. Prostovoljni vozniki s svojo prijaznostjo in skrbnostjo naredijo vožnje prijetne, za dobro počutje pa je pomembno tudi vzdrževati čistočo vozil, k čemur pomembno prispevate vi. Na ta način nam pomagate varovati dostojanstvo starejših, ki uporabljajo našo storitev.</p><br/>
            <p>Hvala še enkrat za vašo izjemno pomoč!</p><br/><br/>
          </div>

          <p>Zavod Sopotniki<br/>
            Janja Smrkolj<br/>
            skrbnica donacij in sponzorstev
          </p>
          <br/><br/>
          <div style="width: 100%; height: 1px; background: #e8e7e8;"></div>
          <br/><br/>
          <p>V obdobju od <b>{{ moment(startDate).format('D. M. YYYY') }} do
            {{ moment(endDate).format('D. M. YYYY') }}</b> so naši prostovoljci <b>koristili skupaj
            {{ donorData.data.length }} donacij</b>, in sicer naslednje dni:<br/>
            <span
              v-for="(item,idx) in donorData.data">{{ idx + 1 }}). {{ moment(item.date).format('dddd') }}, {{ moment(item.date).format('D. M. YYYY') }}<span
              v-if="idx<donorData.data.length-1">, </span></span>.
          </p>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import Vue from "vue";
import VueQrcode from "vue-qrcode";

export default {
  name: 'PoročiloZaDonatorja',

  components: {
    VueQrcode
  },
  data() {
    return {
      donorData: null,
      volunteersData: null,
      startDate: null,
      endDate: null,
      unitId: null,
      reportType: null,
      donorId: null,
    }
  },

  computed: {},

  async mounted() {
    const _this = this;


    if (this.$route.query.jwt) {
      Vue.prototype.$http.interceptors.request.use(
        config => {
          const token = this.$route.query.jwt;
          if (token) {
            if (config.url.includes('https://api.sopotniki.org/')) {
              config.headers.common["Authorization"] = 'Bearer ' + token;
            } else {
              config.headers.common["Authorization"] = token;
            }
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );

    }


    //_this.getVolunteerData();
    //_this.donorData = _this.$route.params.donorData;
    _this.startDate = Number(_this.$route.query.startDate) || _this.$route.params.startDate;
    _this.endDate = Number(_this.$route.query.endDate) || _this.$route.params.endDate;
    _this.unitId = _this.$route.query.unitId || _this.$route.params.unitId;
    _this.donorId = _this.$route.query.donorId || _this.$route.params.donorId;
    _this.reportType = _this.$route.query.reportType || _this.$route.params.reportType;
    await _this.getDailyReports();
    _this.getVolunteerData(_this.donorData.data[0].unit.volunteersId);


  },
  methods: {
    async getVolunteerData(volunteerId) {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + volunteerId)
        .then((res) => {
          _this.volunteersData = res.data.data;
          _this.$vs.loading.close();
          console.log("volunteersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getDailyReports() {
      const _this = this;

      _this.$vs.loading();

      const url = `${Vue.prototype.$config.api.sopotnikiVPS}daily-reports?unit=${_this.unitId}&startDate=${_this.moment(_this.startDate).startOf('month').valueOf()}&endDate=${_this.moment(_this.endDate).endOf('month').valueOf()}`;

      await Vue.prototype.$http.get(url)
        .then((res) => {
          console.log("_this.dailyReportsData", res.data);
          console.log("_this.donorId", _this.donorId);
          const dailyReportsData = _.sortBy(res.data.data, ['date']);

          const dataToGroupBy = _this.reportType + '.id';

          let tmpDonorData = _.chain(dailyReportsData)
            .groupBy(dataToGroupBy)
            .map((value, key) => {
              return {donor: key, donorData: value[0] ? value[0][_this.reportType] : {}, data: value}
            })
            .filter(el => el.donor === _this.donorId.toString())
            .value();

          console.log("tmpDonorData", tmpDonorData);
          _this.donorData = tmpDonorData[0];
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
        });
    },
  }
}
</script>

<style lang="scss">
html, body {
  width: 210mm;
  height: 297mm;
}

@page {
  size: A4;
  margin: 0;
  -webkit-print-color-adjust: exact !important;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.generateZahvalaDonatorjuOuterContainer {
  font-family: 'Open Sans' !important;
  color: black !important;

  .generateZahvalaDonatorjuNaslovnicaContainer {
    position: relative;
    width: 210mm;
    height: 296mm;
    background-size: contain !important;
    -webkit-print-color-adjust: exact !important;
    padding: 40% 0% 15% 8% !important;
    display: block !important;
    color: black !important;
    overflow: hidden !important;
    font-size: 10px;
    text-align: justify;
    page-break-inside: avoid;

    .bgImg {
      width: 210mm;
      height: 296mm;
      position: absolute;
      z-index: -1;
      left: 0px;
      top: 0px;
    }

    u {
      font-weight: bold;
    }

    h3 {
      text-transform: none;
      text-align: left;
      font-size: 26px;
      font-weight: 500;
      margin-top: 7px;
      margin-bottom: 20px;
      letter-spacing: 1px;
      line-height: 35px;
    }

    h4 {
      margin-top: 8px;
      font-size: 22px;
      text-transform: none;
      text-align: left;
      letter-spacing: 2px;
    }

  }

  .generateZahvalaDonatorjuContainer {
    position: relative;
    width: 210mm;
    height: 296mm;
    background-size: contain !important;
    -webkit-print-color-adjust: exact !important;
    padding: 18% 20% 15% 7% !important;
    display: block !important;
    color: black !important;

    overflow: hidden !important;

    font-size: 10px;
    text-align: justify;

    page-break-inside: avoid;

    .bgImg {
      width: 210mm;
      height: 296mm;
      position: absolute;
      z-index: -1;
      left: 0px;
      top: 0px;
    }

    u {
      font-weight: bold;
    }

    &:first-child {
      padding: 30% 28% 15% 7% !important;
    }

    h3 {
      text-transform: none;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    h4 {
      margin-top: 20px;
      font-size: 11px;
      text-transform: none;
      text-align: center;
    }

  }

}
</style>
